<!--description:-->
<!--"获取影片榜单"-->
<template>
  <div class="session-plan">
    <!-- <van-nav-bar :title="`${userType==='personal'?'个人':'集体代表'}-放映评价`" left-arrow @click-left="$router.go(-1)" /> -->
    <van-notice-bar
      left-icon="volume-o"
      text="仅展示本人点播过的放映计划，和当前放映点一周内的点播计划"
      mode="closeable"
    />
    <div class="session-plan__header header" @click="selectShowPoint">
     <van-icon name="location" color="#1989fa" /> {{ userPoint.name }}
    </div>
    <div>
      <van-tabs
        type="line"
        color="#1492FF"
        line-height="0px"
        line-width="0"
        title-class="tab-title"
        swipeable
        background="#EEF0F3"
        animated
      >
        <van-tab>
          <template #title>
            <van-button
              @click="activeIndex = 0"
              style="width: 148px"
              block
              :type="activeIndex == 0 ? 'info' : 'default'"
              size="small"
              :color="activeIndex == 0 ? '#1492FF' : '#B3B5B9'"
              >待评价</van-button
            >
          </template>
          <div class="session-plan__content">
            <div class="session-plan-list">
              <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                v-if="sessionList.length > 0"
              >
                <van-cell v-for="session in sessionList" :key="session.id">
                  <template #default>
                    <div class="session-plan-list__item__header">
                      <div class="session-plan-list__item__header__right">
                        <p>{{ session.playDatetime }}</p>
                        <p>
                          {{ session.showPoint.address }}
                        </p>
                      </div>
                      <div>
                        <van-button
                          type="info"
                          style="width: 80px; height: 36px"
                          round
                          plain
                          @click="goToSessionDetail(session)"
                        >
                          评价
                        </van-button>
                      </div>
                    </div>
                    <div
                      class="session-plan-list__item__content"
                      v-for="(film, f) in session.films"
                      :key="f"
                    >
                      <div class="session-plan-list__item__content__img">
                        <img :src="film.filmPicture" alt="" />
                      </div>
                      <div class="session-plan-list__item__content__info">
                        <div>{{ film.name }}({{ film.year }})</div>
                        <div style="margin: 6px auto">
                          <van-rate
                            v-model="film.score"
                            :size="25"
                            readonly
                            allow-half
                            color="#ffd21e"
                            void-icon="star"
                            void-color="#eee"
                          />
                        </div>
                        <p>{{ film.synopsis }}</p>
                      </div>
                    </div>
                  </template>
                </van-cell>
              </van-list>
              <div v-else>
                <van-empty
                  :show="true"
                  description="该地区暂无放映计划"
                ></van-empty>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab>
          <template #title>
            <van-button
              @click="activeIndex = 1"
              block
              style="width: 148px"
              :type="activeIndex == 1 ? 'info' : 'default'"
              size="small"
              :color="activeIndex == 1 ? '#1492FF' : '#B3B5B9'"
              >已评价</van-button
            >
          </template>
          <div class="session-plan__content">
            <div class="session-plan-list" v-if="sessionList.length > 0">
              <van-cell v-for="session in sessionList" :key="session.id">
                <template #default>
                  <div class="session-plan-list__item__header">
                    <div class="session-plan-list__item__header__right">
                      <p>{{ session.playDatetime }}</p>
                      <p>
                        {{ session.showPoint.address }}
                      </p>
                    </div>
                    <div>
                      <van-button
                        type="default"
                        style="width: 80px; height: 36px"
                        round
                        plain>
                        已评价
                      </van-button>
                    </div>
                  </div>
                  <div
                    class="session-plan-list__item__content"
                    v-for="(film, f) in session.films"
                    :key="f"
                  >
                    <div class="session-plan-list__item__content__img">
                      <img :src="film.filmPicture" alt="" />
                    </div>
                    <div class="session-plan-list__item__content__info">
                      <div>{{ film.name }}({{ film.year }})</div>
                      <div style="margin: 6px auto">
                        <van-rate
                          v-model="film.score"
                          :size="25"
                          readonly
                          allow-half
                          color="#ffd21e"
                          void-icon="star"
                          void-color="#eee"
                        />
                      </div>
                      <p>{{ film.synopsis }}</p>
                    </div>
                  </div>
                </template>
              </van-cell>
            </div>
            <div v-else>
              <van-empty
                :show="true"
                description="该地区暂无放映计划"
              ></van-empty>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { api } from '@/api/index'
import rules from '@/router/routerRules'

export default {
  name: 'sessionPlan',
  data() {
    return {
      userPoint:null,
      activeIndex: 0,
      pageNo: 1,
      pageSize: 5,
      cityName: '请选择',
      loading: false,
      finished: false,
      userType:null,
      select: {
        address: [],
        date: {
          start: '',
          end: '',
        },
      },
      sessionList: [],
      userInfo: null,
    }
  },
  components: {
  },
  created() {
    this.userPoint = this.$store.state.userPoint
    this.userInfo = this.$store.state.userInfo
    this.userType = this.$route.query.type
    if(!this.userPoint){
      this.$router.replace({
        path: '/ruralFilm/selectPoint',
        query: {
          form: this.$route.path,
          back: false,
        },
      })
    }
  },
  mounted() {
    this.onLoad()
  },
  methods: {
    // initPage() {
    //   this.getSessionsList()
    // },
    onLoad() {
      console.log('onLoad')
      this.getSessionsList()
    },
    selectShowPoint () {
      this.$router.push({
        path: '/ruralFilm/selectPoint',
        query: {
          form: this.$route.path,
          back: true,
        },
      })
    },
    onFinishAddress(address) {
      this.select.address = address.map((item) => item.text).join('')
      this.cityName =
        address.length > 0 ? this.getLastItem(address).text : '全省'
      // this.getLastItem(address).text;
      this.$store.commit(
        'changeUserAddress',
        address.map((item) => item.text),
      )
      this.showAreaSelect = false
      this.getSessionsList()
    },
    // 获取场次列表
    getSessionsList() {
      console.log({rules});
      
      api
        .getFilmEvaluationList({
          showPointId: this.userPoint.id,
          status: this.activeIndex,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          roleCode: this.userType==='personal'?rules.personal:rules.group,
        })
        .then((res) => {
          const { result } = res
          this.sessionList = result
          this.loading = false
          if (result.length < this.pageSize) {
            this.finished = true
          } else {
            this.pageNo += 1
          }
        })
    },
    // 跳转到场次评价详情页
    goToSessionDetail(session) {
      this.$router.push({
        path: '/evaluateSubmit',
        query: {
          id: session.id,
          showPoint: session.showPoint,
          films: JSON.stringify(session.films),
          playDatetime: session.playDatetime,
        },
      })
    },
    // 获取数组最后一项
    getLastItem(arr) {
      return arr[arr.length - 1]
    },
  },
  filters: {
    // 过滤器
    // 文字长度过滤
    textLength(text) {
      if (text.length > 4) {
        return text.slice(0, 4) + '...'
      } else {
        return text
      }
    },
  },
  watch: {
    // 监听this.activeIndex
    activeIndex(val) {
      this.pageNo = 1
      this.finished = false
      this.sessionList = []
      this.getSessionsList()
    },
  }
}
</script>

<style scoped lang="less">
::v-deep .van-dropdown-menu__bar {
  box-shadow: none;
}

.session-plan {
  min-height: 100vh;
  background: #f6f7f8;
  padding-bottom: 1vh;
  &__header {
    padding: 16px 16px 0;
  }

  &__content {
    margin: 10px auto;
    border-radius: @border-radius-base;
  }

  &-list {
    height: 75vh;
    overflow-y: auto;
    &__item {
      background: #ffffff;
      margin-right: auto;
      margin-bottom: 10px;
      border-radius: 6px;
      border: 1px solid #e6e8eb;
      &__header {
        height: 68px;
        background: #e7f4ff;
        padding: 8px;
        border-radius: 6px 6px 0px 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        div:nth-child(3) {
          flex: 0.35;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        p {
          margin: 0;
          padding: 5px;
        }

        &__right {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          color: #686b73;
        }
        & > div:last-child {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &__content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        &__img {
          width: 88px;
          height: 132px;
          border-radius: @border-radius-base;
          overflow: hidden;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &__info {
          min-width: 70%;
          //border: 1px solid #000;
          font-size: 17px;
          font-family: PingFangSC-Semibold, PingFang SC, serif;
          font-weight: 600;
          color: #363a44;

          > p {
            width: 229px;
            height: 54px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC, serif;
            font-weight: 400;
            color: #686b73;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}

.header {
  position: relative;

  &-name {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #323233;
    & > div {
      border: 1px solid red;
    }
    span::after {
      position: absolute;
      top: 25px;
      margin-left: 5px;
      margin-top: -5px;
      border: 3px solid;
      border-color: transparent transparent #dcdee0 #dcdee0;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      opacity: 0.8;
      content: ' ';
    }
  }
}
</style>
